import React from "react";
import * as d3 from "d3";
import axios from "axios";
import { create_map } from "./map.js";

class MapCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: "no_data",
      versionid: 0,
      data: {},
    };
    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.map = create_map(this.props.width);
  }

  componentDidMount() {
    this.map.init(this.props.id);
    axios
      .get(process.env.REACT_APP_FLASK_URL + "/map/custom/info")
      .then((res) => {
        var option_data = [{ value: "no_data", name: "No data" }];
        res.data.forEach(function (d) {
          option_data.push({ value: d, name: d.split(".")[0] });
        });
        this.setState({ option_data: option_data });
        var selector_sel = d3
          .select("#map_custom_selector")
          .selectAll("option")
          .data(option_data);
        selector_sel.exit().remove();
        selector_sel
          .enter()
          .append("option")
          .text(function (d) {
            return d.name;
          })
          .attr("value", function (d) {
            return d.value;
          });
        d3.select("#loading").remove();
      });
  }

  handleVersionChange(e) {
    // e.target.value is a string that is the file_name
    var target_url = process.env.REACT_APP_FLASK_URL + "/map/custom";
    var version = e.target.value;
    this.setState({ versionid: e.target.value });
    this.setState({ version: version });

    if (!(version === "no_data")) {
      axios
        .get(target_url, {
          params: { file_name: version },
        })
        .then((res) => {
          this.setState({ data: res.data });
        });
    }
  }

  componentDidUpdate() {
    // When state is updated, this function is called
    // in the future, pass in this.state.data instead
    if (this.state.version === "no_data") {
      // Pass in empty arrays for nodes, labels, links to force redraw and
      // empty map
      this.map.graph({ meta: {}, nodes: [], labels: [], links: [] }).draw();
    } else {
      this.map.graph(this.state.data).draw();
    }
  }

  render() {
    return (
      <div style={{ width: `${this.props.width}px`, margin: "auto" }}>
        <div style={{ margin: "10px" }}>
          <p id="loading">Loading...</p>
        </div>
        <div style={{ margin: "10px", marginTop: "5px" }}>
          <form id="map_custom_form" style={{ margin: "10px" }}>
            <select
              id="map_custom_selector"
              value={this.state.versionid}
              onChange={this.handleVersionChange}
            ></select>
          </form>
          <div id={this.props.id}></div>
        </div>
      </div>
    );
  }
}

export { MapCustom };
