import React from "react";
import "./MapCo2.css";
import { MapCo2 } from "./MapCo2.js";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./image-gallery.css"; // custom
import axios from "axios";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [12, 5, 6, 6, 9, 10],
      width: this.props.width, // should be in [700, 975]
      id: "co2map",
      maptype: "normal",
    };
  }
  render() {
    return (
      <div>
        <h2 id="page_subtitle"> </h2>
        <MapCo2
          id={this.state.id}
          version={this.state.version}
          data={this.state.data}
          width={this.state.width}
          maptype={this.state.maptype}
        />
        <p id="last_updated" style={{ marginBottom: "10px" }}></p>
        <div style={{ marginTop: "30px", marginBottom: "20px" }}>
          <h2>How to read this map</h2>
          <p>
            This map displays the hourly carbon footprint of the US electricity
            system (move the slider to change the date). The map shows the
            carbon embodied in electricity consumed and exchanged; and
            consumption-based carbon intensity of grid electricity. The radius
            of the nodes and width of the arrows scale with consumption and
            trade, respectively. The color of the nodes and arrows scale with
            consumption-based carbon intensity. A reference for balancing area
            acronyms can be found{" "}
            <a href="https://eiadataxchange-c.force.com/resource/1495723321000/BA_Codes_930">
              here
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

class About extends React.Component {
  render() {
    return (
      <div>
        <div style={{ marginTop: "30px", marginBottom: "20px" }}>
          <h2>About this work</h2>
          <p>
            For a 1-hour overview of this research, see <a href="https://www.youtube.com/watch?v=P7m1hCMFi-Q) (April 7th, 2022)">this talk</a>.
          </p>
          <p>
            The environmental quality of the electricity flowing through
            electric grids varies by location, season, and time of day. To
            encourage and guide decarbonization efforts, better tools are needed
            to monitor the emissions embodied in electricity consumption,
            production, imports, and exports. Whether for policymakers designing
            energy efficiency and renewable programs, regulators enforcing
            emissions standards, or large electricity consumers greening their
            supply, greater resolution is needed for electric sector emissions
            indices to evaluate progress against current and future goals.
          </p>
          <p>
            The economic input–output model is adapted to track emissions flows
            through electric grids and quantify the pollution embodied in
            electricity production, exchanges, and, ultimately, consumption for
            the continental US balancing authorities in [1]. Subsequent work
            introduces a physics-informed data reconciliation framework to
            automate electric system operating data cleaning [2]. The data
            reconciliation framework enables researchers to perform similar
            analyses in near real-time and to continuously monitor emissions
            rates in the US electricity system.
          </p>
          <p>
            We hope that researchers, policymakers and private sector actors
            with an interest in tracking electric sector emissions will benefit
            from this work.
          </p>
          <h2>How do the data at energy.stanford.edu/gridemissions compare to what the US EIA now releases at their <a href="https://www.eia.gov/electricity/gridmonitor/about">Hourly Electric Grid Monitor?</a></h2>
          <p>
          The main difference is that the EIA calculates an estimate for direct emissions factors, while this website calculates an estimate for Life-Cycle Analysis emissions factors. We use emissions factors provided by the IPCC for each generation source (see "Code and Data" page). In contrast, the EIA calculates average annual emissions factors for direct emissions from natural gas, coal, and petroleum in each balancing area (see below for more detail). After calculating the emissions produced in each balancing area, the EIA uses our methodology [1] to compute consumed emissions.
          </p>
          <p>
          The following quote is from the EIA's website.
          </p>
          <p style={{ marginLeft: "2em" }}><i>
          We use average annual CO2 emissions factors (pounds [lbs] of CO2 per kilowatthour [kWh] of generation) to estimate CO2 emissions for each BA. We calculate these factors for natural gas, coal, and petroleum using reported generation and our estimated emissions for generators operated by electric utilities and independent power producers within each BA fleet of generators consuming those fuels. CO2 emissions from fuels other than natural gas, coal, and petroleum use the same emissions factor for the entire United States. We exclude data from combined-heat-and-power facilities. We estimate CO2 emissions imported from Canada and Mexico by applying the average U.S. emissions rate per megawatthour of generation to the megawatthours of imports.
        </i></p>
        <p style={{ marginLeft: "2em" }}><i>
        We estimate CO2 emissions for generators operated by electric utilities and independent power producers by using the fuel consumption (in million British thermal units [MMBtu]) reported on Form EIA-923 and average CO2 emission factors (kilogram [kg] per MMBtu) published by the U.S. Environmental Protection Agency. You can find more information on the method of estimating CO2 emissions at power plants in the technical notes of the Electric Power Annual.
        </i></p>
        <p style={{ marginLeft: "2em" }}><i>
        We calculate both the CO2 emissions intensity of generation (or, the production emissions intensity) and the CO2 emissions intensity of load (or, the consumption emissions intensity) for each BA, for every hour that has the necessary data submitted.
        </i></p>
        <p style={{ marginLeft: "2em" }}><i>
        To calculate the CO2 emissions intensity of load, we associate estimated emissions with the transfer of electricity between BAs using a <a href="https://www.pnas.org/doi/full/10.1073/pnas.1912950116">multiregional input–output model (MRIO)</a><sup>1</sup>. To calculate both the temporal and geographic variability of emissions intensity consumed within each BA, we assign emissions intensities to both the imported and exported electricity that is transferred between all BAs in a given hour.
        </i></p>
        </div>
      </div>
    );
  }
}

class HeatmapGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      all_images: {},
      images: [],
      rendering: <div></div>,
      scale: "fixed_scale",
      base_url:
        process.env.REACT_APP_S3_BUCKET_URL + "/figures/heatmap_report/",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    axios
      .get(this.state.base_url + "contents.json")
      .then((res) => {
        this.setState({ all_images: res.data });

        // send fake event to trigger the update of this.state.images
        this.handleChange({ target: { value: this.state.scale } });
        // Populate options for scale dropdown using d3 selections
        //    var scale_options = Object.keys(res.data["2021"])
        //    var selector_sel = d3.select("#heatmap_select")
        //        .selectAll("option").data(scale_options);
        //    selector_sel.exit().remove();
        //    selector_sel.enter().append("option")
        //        .text(function (d) { return d; })
        //        .attr("value", function (d) { return d; });
      })
      .catch((err) => alert("Could not fetch images from server!\n" + err));
  }

  handleChange(event) {
    var scale = event.target.value;
    this.setState({
      scale: scale,
    });
    var images = [];
    var year = "2023";
    var image_url = this.state.base_url + year + "/" + scale + "/";
    this.state.all_images[year][scale].forEach((element) => {
      images.push({
        original: image_url + element + ".png",
        thumbnail: image_url + element + "-thumbnail.png",
        thumbnailLabel: element,
      });
    });

    this.setState({ images: images });
    this.setState({
      rendering: (
        <div>
          <select
            id="heatmap_select"
            value={scale}
            onChange={this.handleChange}
          >
            {Object.keys(this.state.all_images["2023"]).map((k) => {
              return (
                <option key={k} value={k}>
                  {k.replace("_", " ")}
                </option>
              );
            })}
          </select>
          <ImageGallery items={images} showPlayButton={false} />
          <div style={{ marginTop: "30px", marginBottom: "20px" }}>
            <h2>How to read these images</h2>
            <p>
              These heatmaps show the hourly consumption-based carbon intensity
              of different balancing areas in the US electricity system. In the
              images, rows correspond to hours of the day, and columns
              correspond to days of the year. A reference for balancing area
              acronyms can be found{" "}
              <a href="https://eiadataxchange-c.force.com/resource/1495723321000/BA_Codes_930">
                here
              </a>
              .
            </p>
          </div>
        </div>
      ),
    });
  }

  render() {
    return this.state.rendering;
  }
}

class TimeseriesGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      all_images: {},
      images: [],
      rendering: <div></div>,
      base_url:
        process.env.REACT_APP_S3_BUCKET_URL + "/figures/timeseries_report/",
      plot_type: "Carbon intensity",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    axios
      .get(this.state.base_url + "contents.json")
      .then((res) => {
        this.setState({ all_images: res.data });
        // send fake event to trigger the update of this.state.images
        this.handleChange({ target: { value: this.state.plot_type } });
      })
      .catch((err) => alert("Could not fetch images from server!\n" + err));
  }

  handleChange(event) {
    var plot_type = event.target.value;
    this.setState({
      plot_type: plot_type,
    });
    var image_url = this.state.base_url + plot_type + "/";
    var images = [];
    this.state.all_images[plot_type].forEach((element) => {
      images.push({
        original: image_url + element + ".png",
        thumbnail: image_url + element + "-thumbnail.png",
        thumbnailLabel: element,
      });
    });

    this.setState({ images: images });
    this.setState({
      rendering: (
        <div>
          <select
            id="timeseries_select"
            value={plot_type}
            onChange={this.handleChange}
          >
            {Object.keys(this.state.all_images).map((k) => {
              return (
                <option key={k} value={k}>
                  {k}
                </option>
              );
            })}
          </select>
          <ImageGallery items={images} showPlayButton={false} />
          <div style={{ marginTop: "30px", marginBottom: "20px" }}>
            <h2>How to read these plots</h2>
            <p>
              These plots show weekly data. The full lines correspond to the
              weekly average, and the shaded area indicates the 10th to 90th
              percentiles. A reference for balancing area acronyms can be found{" "}
              <a href="https://eiadataxchange-c.force.com/resource/1495723321000/BA_Codes_930">
                here
              </a>
              .
            </p>
          </div>
        </div>
      ),
    });
  }

  render() {
    return this.state.rendering;
  }
}

class Code extends React.Component {
  render() {
    return (
      <div>
        <div style={{ marginTop: "30px", marginBottom: "20px" }}>
          <h2>Code and Data</h2>
          Code to download and manipulate data from our API is available in the{" "}
          <a href="https://github.com/jdechalendar/gridemissions/">
            Github repository
          </a>{" "}
          for this project. Documentation can be found{" "}
          <a href="https://docs.gridemissions.jdechalendar.su.domains/">
            here
          </a>{"."}
        The README file in that repository and the documentation contain
          technical instructions on how to install the software, retrieve and
          manipulate these datasets, data naming conventions, etc.
          <ul>
            <li>You can {" "}
                <a href="https://api.gridemissions.com/bulk/latest">
                  download data in bulk here
                </a>{" "}(.tar.gz archive with six-month files)
            </li>
            <li>
              Explore how to use our API in a{" "}
              <a href="https://colab.research.google.com/drive/1HYHqiA2iA-vVMuqFHrKtUUkdPLN5UJYS">
                jupyter notebook
              </a>
              . Note that only two weeks of historical data is stored by the
              API. For more, download data in bulk.
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export { Home, About, HeatmapGallery, TimeseriesGallery, Code };
