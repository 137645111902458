import React from "react";
import * as d3 from "d3";
import axios from "axios";
import { create_map } from "./map.js";

class MapCo2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: "no_data",
      versionid: 0,
      data: {},
    };
    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.map = create_map(this.props.width);
  }

  componentDidMount() {
    this.map.init(this.props.id);

    axios.get(process.env.REACT_APP_FLASK_URL + "/info").then((res) => {
      this.setState({ info: res.data });
      d3.select("#loading").remove();
      d3.select("#last_updated").text(
        `Data last updated: ${d3.isoParse(res.data.elec.end)}`
      );
    });

    axios.get(process.env.REACT_APP_FLASK_URL + "/map/info").then((res) => {
      var option_data = [{ value: "no_data", name: "No data" }];
      res.data.forEach(function (d) {
        option_data.push({ value: d, name: d.split(".")[0] });
      });
      this.setState({ option_data: option_data });

      d3.select("#map_info_selector2")
        .attr("min", 0)
        .attr("max", Math.max(option_data.length - 1, 0))
        .attr("type", "range")
        .property(
          "value",
          Math.max(Math.floor(0.8 * (option_data.length - 1)), 0)
        );

      // Send a fake event with just what we need to trigger the update
      this.handleVersionChange({
        target: document.getElementById("map_info_selector2"),
      });
    });
  }

  handleVersionChange(e) {

    // e.target.value is an integer that denotes where the slider is
    var target_url = process.env.REACT_APP_FLASK_URL + "/map/data";
    var version = this.state.option_data[e.target.value].value;
    this.setState({ versionid: e.target.value });
    this.setState({ version: version });

    if (!(version === "no_data")) {
      axios
        .get(target_url, {
          params: { file_name: version },
        })
        .then((res) => {
          // console.log(res);
          this.setState({ data: res.data });
        });
    }
  }

  componentDidUpdate() {
    // When state is updated, this function is called
    // in the future, pass in this.state.data instead
    if (this.state.version === "no_data") {
      // Pass in empty arrays for nodes, labels, links to force redraw and
      // empty map
      this.map.graph({ meta: {}, nodes: [], labels: [], links: [] }).draw();
    } else {
      this.map.graph(this.state.data).draw();
    }
  }

  render() {
    return (
      <div style={{ width: `${this.props.width}px`, margin: "auto" }}>
        <div style={{ margin: "10px" }}>
          <p id="loading">Loading...</p>
        </div>
        <div style={{ margin: "10px", marginTop: "5px" }}>
          <input
            style={{ width: this.props.width, margin: "5px" }}
            id="map_info_selector2"
            type="hidden"
            value={this.state.versionid}
            onChange={this.handleVersionChange}
          ></input>
          <div id={this.props.id}></div>
        </div>
      </div>
    );
  }
}

export { MapCo2 };
