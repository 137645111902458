import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AppCustom from "./AppCustom";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_APP_TYPE === "custom") {
    console.log("Loading AppCustom...")
    ReactDOM.render(<AppCustom />, document.getElementById("root"));
} else {
    console.log("Loading App...")
    ReactDOM.render(<App />, document.getElementById("root"));
}




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
