import React from "react";
import "./App.css";
import { MapCustom } from "./MapCustom";

class AppCustom extends React.Component {
  state = {
    width: 940, // should be in [700, 975]
    data: [12, 5, 6, 6, 9, 10],
    id: "co2map",
    maptype: "custom",
  };
  render() {
    return (
      <div>
        <h1>Map Visualization</h1>
        <MapCustom id={this.state.id} version={this.state.version} data={this.state.data} width={this.state.width} height={this.state.height} maptype={this.state.maptype} />
      </div>
    );
  }
}

export default AppCustom;
