// This was started on 20191203 in an atempt to make the map making code
// clearer and more flexible.
// Style is heavily inspired from topojson (see e.g. topojson-client) and from
// the sankey code.
// I still need to be able to update the map and modify it - these modifier
// functions will be called in the componentDidMount in MapCo2.js

import * as d3 from "d3";
import * as topojson from "topojson";
// import ustopo from './data/us-10m.v1.json';

// topo files were downloaded from https://github.com/topojson/us-atlas
// The projected data were designed to fit a 975×610 viewport.
// Using the unprojected data means we can size the map automatically, but that is also slower.
// import ustopo_projected from './data/states-albers-10m.json';
import ustopo_unprojected from "./data/states-10m.json";

var schemeRdYlGn_reversed = d3.schemeRdYlGn[9].reverse();
// var schemeRdYlBu = d3.schemeRdYlBu[10];

var HEIGHT_MAP = 610;
var WIDTH_MAP = 975;

export function create_map(requested_width) {
  // requested width should be in [700, 975]
  // initialize some global variables
  // values will be set when reading the data file

  // Ignoring the requested width and height for now. Will need to use this to
  // allow the map's size to be set automatically
  //  console.log(requested_width);
  // remove the requested_height parameter

  var map = {},
    graph = {},
    fieldRadius = "",
    fieldLineWidth = "",
    fieldCircle = "",
    fieldLineColor = "",
    map_timestamp = "",
    // width = WIDTH_MAP,
    // heightMap = HEIGHT_MAP,
    width = requested_width,
    heightMap = (requested_width / WIDTH_MAP) * HEIGHT_MAP,
    height = heightMap + 60,
    defs = {},
    leg = {},
    leg2 = {},
    leg3 = {},
    title = {},
    wecc_title = {},
    eic_title = {},
    watermark = {},
    baGraphContainer = {},
    radius = d3.scaleSqrt().range([5, 50]),
    // cutoff radius to decide whether text is in or out node
    radLimText = 19,
    radLimTextNumber = 21, // cutoff radius to decide if we add the value
    lineWidth = d3.scaleLinear().range([3, 15]),
    colorScale = {},
    colorExtent = {};

  // Projection used for the 975×610 viewport
  // var proj = d3.geoAlbersUsa().scale(1300).translate([487.5, 305]);

  //  need to figure out how the scale/translate params are computed. The ones above are for a 975×610 viewport
  var proj = d3
    .geoAlbers()
    .scale(width * 1.33)
    .translate([width / 2, heightMap / 2]);

  map.graph = function (_) {
    if (!arguments.length) return graph;
    graph = _;
    return map;
  };

  map.draw = function () {
    // Note: the order matters here. In particular the links have to be created
    // before the nodes. The links are defined with reference to nodes. When
    // the nodes are created, their position is computed and then the line
    // positions are set based on the node positions. But the links have to
    // exist before the node positions are created for this to work
    if ("meta" in graph) updateMeta();
    if ("links" in graph) updateLinks();
    if ("nodes" in graph) updateNodes();
    if ("labels" in graph) updateLabels();
    if ("meta" in graph) updateTitles();
    return map;
  };

  map.init = function (target_id) {
    var path = d3.geoPath().projection(proj);
    var ustopo = ustopo_unprojected;

    // Define containers here so layering is correct
    var svg = d3
      .select(`#${target_id}`)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    svg
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("class", "sea");
    var mapContainer = svg
      .append("svg")
      .attr("width", width)
      .attr("height", heightMap);

    // note that baGraphContainer is defined outside this function so it
    // can be reused
    baGraphContainer = svg
      .append("svg")
      .attr("width", width)
      .attr("height", heightMap);

    // Create background map
    // Plot the land area
    mapContainer
      .append("path")
      .datum(
        topojson.merge(
          ustopo,
          ustopo.objects.states.geometries.filter(function (d) {
            return true;
          })
        )
      )
      .attr("class", "land")
      .attr("d", path);
    // add state lines here instead of egrid regions
    mapContainer
      .append("path")
      .datum(
        topojson.mesh(ustopo, ustopo.objects.states, function (a, b) {
          return a !== b;
        })
      )
      .attr("class", "border border--egrid")
      .attr("d", path);
    // Add lines for the Interconnects
    mapContainer
      .append("path")
      .attr("class", "interconnect")
      .attr("d", "M420 50 L420 370 L320 530 M420 370 L650 530");

    defs = baGraphContainer.append("defs");
    defs
      .append("marker")
      .attr("id", "legendArrow")
      .attr("viewBox", "-0 -2.5 5 5")
      .attr("refX", 0)
      .attr("orient", "auto")
      .attr("markerUnits", "strokeWidth")
      .append("svg:path")
      .attr("d", "M 3,0 L 0 ,1.5 L 0,-1.5 z")
      .attr("fill", "grey");

    // legend for colors
    leg = svg
      .append("g")
      .attr(
        "transform",
        `translate(${(width / WIDTH_MAP) * 480} ${heightMap + 30})`
      )
      .attr("class", "legend")
      .attr("id", "leg");
    leg
      .append("text")
      .attr("class", "legtitle")
      .attr("y", 25)
      .style("text-anchor", "start");

    // legend for circles
    leg2 = svg
      .append("g")
      .attr("class", "legend")
      .attr("id", "leg2")
      .attr(
        "transform",
        `translate(${(width / WIDTH_MAP) * 140}, ${heightMap + 40})`
      );
    leg2
      .append("text")
      .attr("class", "legtitle")
      .attr("y", 15)
      .style("text-anchor", "middle");

    // legend for links
    leg3 = svg
      .append("g")
      .attr("class", "legend")
      .attr(
        "transform",
        `translate(${(width / WIDTH_MAP) * 375} , ${heightMap + 40})`
      )
      .attr("id", "leg3");
    leg3
      .append("text")
      .attr("class", "legtitle")
      .attr("y", 15)
      .style("text-anchor", "middle");

    // title
    title = svg
      .append("g")
      .attr(
        "transform",
        `translate(${(1.2 * width) / 2}, ${(heightMap / HEIGHT_MAP) * 20})`
      )
      .attr("class", "graphtitle")
      .append("text")
      .attr("y", 15)
      .style("text-anchor", "middle");

    wecc_title = svg
      .append("g")
      .attr(
        "transform",
        `translate(${(width / WIDTH_MAP) * 120}, ${(heightMap / HEIGHT_MAP) * 470
        })`
      )
      .attr("class", "graphsubtitle")
      .append("text")
      .attr("y", 15)
      .style("text-anchor", "middle");
    eic_title = svg
      .append("g")
      .attr(
        "transform",
        `translate(${(width / WIDTH_MAP) * 750}, ${(heightMap / HEIGHT_MAP) * 70
        })`
      )
      .attr("class", "graphsubtitle")
      .append("text")
      .attr("y", 15)
      .style("text-anchor", "middle");
    watermark = svg
      .append("g")
      .attr(
        "transform",
        `translate(${(width / WIDTH_MAP) * 970}, ${(heightMap / HEIGHT_MAP) * 0
        })`
      )
      .attr("class", "watermark")
      .append("text")
      .attr("y", 15);

    watermark.text("energy.stanford.edu/gridemissions");

    return map;
  };

  function updateMeta() {
    fieldRadius = graph.meta.fieldRadius;
    fieldCircle = graph.meta.fieldCircle;
    fieldLineWidth = graph.meta.fieldLineWidth;
    fieldLineColor = graph.meta.fieldLineColor;
    if ("timestamp" in graph.meta) map_timestamp = graph.meta.timestamp;

    if (
      "colorScale" in graph.meta &&
      graph.meta.colorScale === "viridis" &&
      "nodes" in graph
    ) {
      // Todo: change the name of this colorscale as it is not viridis anymore
      // colorExtent = (graph.nodes.length > 0) ? d3.extent(graph.nodes.map(
      //   function(d) { return (fieldCircle in d ? d[fieldCircle] : 0); })) : [];
      // console.log(colorExtent)

      // var colors = ["#FAE300", "#FAC200", "FD7900", "#E31A31A", "#CF1750",
      //               "#AE07E7F", "#7A0DA6", "#482BBD", "2C51BE"];

      // var mydomain = [-20, -15, -10, -6, -4, -2, 0, 5];
      var mydomain = [-20, -15, -10, -5, 0];
      colorExtent = [-25, 10];
      var myrange = [colorExtent[0]].concat(mydomain);
      myrange.push(colorExtent[1]);
      myrange = myrange.reduce(function (result, value, index, array) {
        if (index < array.length - 1)
          result.push((array[index] + array[index + 1]) / 2);
        return result;
      }, []);
      var width = myrange[myrange.length - 1] - myrange[0];
      myrange = myrange
        .slice()
        .reverse()
        .map((x) => x / width - myrange[0] / width)
        .map(d3.interpolatePlasma);

      colorScale = d3.scaleThreshold().domain(mydomain).range(myrange);
    } else {
      colorScale = d3
        .scaleThreshold()
        .domain([100, 200, 300, 400, 500, 600, 700, 900])
        .range(schemeRdYlGn_reversed);
      colorExtent = [0, 1100]; // 20200111: hard-code the limits
    }
  }

  function updateNodes() {
    // set domain for radius scale
    radius.domain(
      d3.extent(
        graph.nodes.map(function (d) {
          return d[fieldRadius];
        })
      )
    );
    // location of nodes
    graph.nodes.map(function (d, i) {
      [d["x"], d["y"]] = proj(d.coords);
      return 0;
    });

    // UPDATE SEL
    var nodeSel = baGraphContainer.selectAll(".node").data(graph.nodes);

    // EXIT SEL
    nodeSel.exit().remove();

    // ENTER SEL
    var nodeEnter = nodeSel.enter().append("g").attr("class", "node");
    nodeEnter.append("circle");
    nodeEnter.append("text");

    // MERGED ENTER + UPDATE SEL
    nodeSel = nodeEnter.merge(nodeSel);
    nodeSel
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      })
      .each(updateLinePos) // update lines now we know where the nodes are
      .call(d3.drag().on("drag", dragNodes));
    nodeSel
      .select("circle")
      .attr("r", function (d) {
        return d[fieldRadius] ? radius(d[fieldRadius]) : 5;
      })
      .attr("fill", function (d) {
        return d[fieldCircle] ? colorScale(d[fieldCircle]) : "grey";
      })
      .attr("stroke", "black");

    nodeSel.select("text").selectAll("tspan").remove();
    let nodeText = nodeSel
      .filter((d) =>
        d[fieldRadius] ? radius(d[fieldRadius]) > radLimTextNumber : false
      )
      .select("text")
      .attr("class", "label")
      .attr("dy", "-.3em")
      .text(""); // make sure we clean up from a previous iteration
    nodeText.append("tspan").text((d) => d.shortNm);
    nodeText
      .append("tspan")
      //           .text((d) => `${d.E_D.toFixed(0)} ${graph.meta.unit}`)
      .text((d) => d[fieldRadius].toFixed(0))
      .attr("x", "0")
      .attr("dy", "1.2em");

    nodeSel
      .filter((d) =>
        d[fieldRadius] ? radius(d[fieldRadius]) <= radLimTextNumber : true
      )
      .select("text")
      .attr("class", "label")
      .attr("dy", "0.3em")
      .text(function (d) {
        let rad = d[fieldRadius] ? radius(d[fieldRadius]) : 5;
        if (rad > radLimText) return d.shortNm;
        else {
          return "";
        }
      });

    updateLegend();
  }

  function updateLabels() {
    graph.labels.map(function (d, i) {
      [d["x"], d["y"]] = proj(d.coords);
      return 1;
    });
    var label2Sel = baGraphContainer.selectAll(".label2").data(graph.labels);
    label2Sel.exit().remove();

    var label2Enter = label2Sel.enter().append("g").attr("class", "label2");
    label2Enter.append("text").attr("class", "label-small").attr("dy", "0.3em");

    // console.log(graph.labels);

    label2Sel = label2Enter.merge(label2Sel);
    label2Sel
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      })
      .call(d3.drag().on("drag", dragLabels));
    label2Sel.select("text").text(function (d) {
      let rad = d[fieldRadius] ? radius(d[fieldRadius]) : 5;
      if (rad <= radLimText) return d.shortNm;
      else {
        return "";
      }
    });
  }

  function dragNodes(event, d) {
    d.x = event.x;
    d.y = event.y;
    // update node position
    d3.select(this).attr("transform", function (d) {
      return "translate(" + d.x + "," + d.y + ")";
    });
    updateLinePos(d); // update line position
  }

  function updateLinePos(node) {
    var line = d3.selectAll(".link");
    line
      .filter(function (l) {
        return l.sourceNode === node;
      })
      .attr("d", calcLinePos);
    line
      .filter(function (l) {
        return l.targetNode === node;
      })
      .attr("d", calcLinePos);
  }

  function calcLinePos(d) {
    const deltaX = d.targetNode.x - d.sourceNode.x;
    const deltaY = d.targetNode.y - d.sourceNode.y;
    const dist = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    const normX = deltaX / dist;
    const normY = deltaY / dist;
    const sourcePadding = d.sourceNode[fieldRadius]
      ? radius(d.sourceNode[fieldRadius])
      : 5;
    const targetPadding =
      (d.targetNode[fieldRadius] ? radius(d.targetNode[fieldRadius]) : 5) +
      2 * (d[fieldLineWidth] ? lineWidth(d[fieldLineWidth]) : 3);
    const sourceX = d.sourceNode.x + sourcePadding * normX;
    const sourceY = d.sourceNode.y + sourcePadding * normY;
    const targetX = d.targetNode.x - targetPadding * normX;
    const targetY = d.targetNode.y - targetPadding * normY;
    return `M${sourceX},${sourceY}L${targetX},${targetY}`;
  }

  function dragLabels(event, d) {
    d.x = event.x;
    d.y = event.y;
    d3.select(this).attr("transform", function (d) {
      return "translate(" + d.x + "," + d.y + ")";
    });
  }

  function updateLinks() {
    lineWidth.domain(
      d3.extent(
        graph.links.map(function (d) {
          return d[fieldLineWidth];
        })
      )
    );
    graph.links.forEach(function (d) {
      d.sourceNode = graph.nodes[d.source];
      d.targetNode = graph.nodes[d.target];
    });

    // Note: for markers the typical d3 update pattern does not seem to work
    // (because they are part of the svg defs?) In any case, just starting over
    // from scratch is easier
    defs.select(".arrow").remove();
    var arrow = defs.selectAll(".arrow").data(graph.links);
    arrow
      .enter()
      .append("marker")
      .attr("class", "arrow")
      .merge(arrow)
      .attr("id", function (d) {
        return "arrow" + d.source + "_" + d.target;
      })
      .attr("viewBox", "-0 -2.5 5 5")
      .attr("refX", 0)
      .attr("orient", "auto")
      .attr("markerUnits", "strokeWidth")
      .append("svg:path")
      .attr("d", "M 3,0 L 0.3 ,1.5 L 0.3,-1.5 z")
      .attr("fill", function (d) {
        return d[fieldLineColor] ? colorScale(d[fieldLineColor]) : "grey";
      });

    var linkSel = baGraphContainer.selectAll(".link").data(graph.links);

    linkSel.exit().remove();
    linkSel
      .enter()
      .append("g")
      .append("path")
      .merge(linkSel)
      .attr("class", "link")
      .attr("marker-end", function (d) {
        return "url(#arrow" + d.source + "_" + d.target + ")";
      })
      .attr("stroke", function (d) {
        return d[fieldLineColor] ? colorScale(d[fieldLineColor]) : "grey";
      })
      .style("stroke-width", function (d) {
        return d[fieldLineWidth] ? lineWidth(d[fieldLineWidth]) : 3;
      });
  }

  function updateTitles() {
    if ("title" in graph.meta) {
      let total = graph.nodes
        .map((el) => el[fieldRadius] | 0.)
        .reduce((a, c) => a + c, 0);
      // // HACK to change the title
      //       title.text(`SPRING - NIGHTTIME ${graph.meta.title} CONSUMPTION
      var parser = d3.timeParse("%Y%m%dT%H MT");
      var formatter = d3.timeFormat("%b %d, %Y %I%p Mountain Time");
      var append_txt = "";
      if (graph.meta.title === "CARBON") {
        append_txt = "CO<sub>2-eq</sub>";
      }
      d3.select("#page_subtitle").html(
        `${formatter(parser(map_timestamp))} (${total.toFixed(0)} ${graph.meta.unit
        } ${append_txt})`
      );
      // title.text(`${formatter(parser(map_timestamp))}
      //            (${total.toFixed(0)} ${graph.meta.unit} ${append_txt})`);
      // title.text(`${formatter(parser(map_timestamp))} ${graph.meta.title} CONSUMPTION
      //              (${total.toFixed(0)} ${graph.meta.unit} total)`);
      let total_wecc = graph.nodes
        .filter((el) => el.interconnect === "wecc")
        .map((el) => el[fieldRadius])
        .reduce((a, c) => a + c, 0);
      let total_eic = graph.nodes
        .filter((el) => el.interconnect === "eic")
        .map((el) => el[fieldRadius])
        .reduce((a, c) => a + c, 0);

      wecc_title.selectAll("tspan").remove();
      wecc_title.append("tspan").text("Western Interconnect");
      wecc_title
        .append("tspan")
        .text(`${total_wecc.toFixed(0)} ${graph.meta.unit}`)
        .attr("x", "0")
        .attr("dy", "1.2em");
      eic_title.selectAll("tspan").remove();
      eic_title.append("tspan").text("Eastern Interconnect");
      eic_title
        .append("tspan")
        .text(`${total_eic.toFixed(0)} ${graph.meta.unit}`)
        .attr("x", "0")
        .attr("dy", "1.2em");
    } else {
      title.text("");
      wecc_title.text("");
      eic_title.text("");
    }

    // Update legend title also
    if ("legColorTitle" in graph.meta)
      d3.select("#leg").select(".legtitle").text(graph.meta.legColorTitle);
    else d3.select("#leg").select(".legtitle").text("");
    if ("legCircleTitle" in graph.meta)
      d3.select("#leg2").select(".legtitle").text(graph.meta.legCircleTitle);
    else d3.select("#leg2").select(".legtitle").text("");
    if ("legLineTitle" in graph.meta)
      d3.select("#leg3").select(".legtitle").text(graph.meta.legLineTitle);
    else d3.select("#leg3").select(".legtitle").text("");
  }

  function updateLegend() {
    var colorDomain = graph.nodes.length > 0 ? colorScale.domain() : [];
    // console.log(colorExtent);
    // console.log(colorScale.range());
    // Legend for colors
    var x = d3
      .scaleLinear()
      .domain(colorExtent)
      .range([0, (width / WIDTH_MAP) * 470]);

    var xAxis = d3.axisTop(x).tickSize(5).tickValues(colorDomain);

    leg.call(xAxis);
    leg.select(".domain").remove();

    var legData =
      graph.nodes.length > 0
        ? colorScale.range().map(function (color) {
          var d = colorScale.invertExtent(color);
          if (d[0] == null) d[0] = x.domain()[0];
          if (d[1] == null) d[1] = x.domain()[1];
          return d;
        })
        : [];

    // console.log(legData);

    var legSel = leg.selectAll("rect").data(legData);
    legSel.exit().remove();

    legSel
      .enter()
      .append("rect")
      .merge(legSel)
      .attr("height", 8)
      .attr("x", function (d) {
        return x(d[0]);
      })
      .attr("width", function (d) {
        return Math.abs(x(d[1]) - x(d[0]));
      })
      .attr("fill", function (d) {
        return colorScale(d[0]);
      });

    // Legend for circle size
    var maxRad = radius.domain()[1];
    var leg2Data =
      graph.nodes.length > 0 ? [0.1 * maxRad, 0.35 * maxRad, 0.9 * maxRad] : [];

    var leg2Sel = leg2.selectAll("g").data(leg2Data);
    leg2Sel.exit().remove();

    var leg2Enter = leg2Sel.enter().append("g");

    leg2Enter.append("circle");

    leg2Enter.append("text").attr("class", "legend-text").attr("dy", "1.3em");

    leg2Sel = leg2Enter.merge(leg2Sel);
    leg2Sel
      .select("circle")
      .attr("cy", function (d) {
        return -radius(d);
      })
      .attr("r", radius);
    leg2Sel
      .select("text")
      .attr("y", function (d) {
        return -2 * radius(d);
      })
      .text(d3.format(".1s"));

    // legend for link size
    if ("links" in graph) {
      var maxWidth = lineWidth.domain()[1];
      var leg3Data =
        graph.nodes.length > 0
          ? [
            { w: 0.15 * maxWidth, l: 120 },
            { w: 0.5 * maxWidth, l: 78 },
            { w: maxWidth, l: 43 },
          ]
          : [];
      var leg3Sel = leg3.selectAll("g").data(leg3Data);

      leg3Sel.exit().remove();
      var leg3Enter = leg3Sel.enter().append("g");

      leg3Enter.append("path").attr("stroke", "grey");

      leg3Enter.append("text").attr("class", "legend-text").attr("dy", "0.5em");
      leg3Sel = leg3Enter.merge(leg3Sel);
      leg3Sel
        .select("text")
        .attr("y", function (d) {
          return -14 - lineWidth(d.w);
        })
        .attr("x", function (d) {
          return d.l - 60;
        })
        .text(function (d) {
          return d3.format(".2f")(d.w);
        });
      leg3Sel
        .select("path")
        .attr("marker-end", function (d) {
          return "url(#legendArrow)";
        })
        .attr("d", function (d) {
          return (
            "M " +
            (d.l - 48) +
            "," +
            (-5 - lineWidth(d.w) / 2) +
            " L -48," +
            (-5 - lineWidth(d.w) / 2) +
            ""
          );
        })
        .style("stroke-width", function (d) {
          lineWidth(d.w);
        })
        .attr("stroke-width", function (d) {
          return lineWidth(d.w);
        });
    }
  }

  return map;
}
