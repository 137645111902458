import React from "react";
import "./App.css";
import * as views from "./Views.js";
import { Route, NavLink, HashRouter } from "react-router-dom";

// Change maptype to "custom" to load data from a folder - to be used locally only

class App extends React.Component {
  state = {
    width: 940, // should be in [700, 975]
  };
  render() {
    return (
      <HashRouter>
        {/* <div style={{width:`${this.state.width}px`, margin:"auto"}}> */}
        <div style={{ width: "100%", margin: "auto" }}>
          <div className="banner">
            <h1 id="page_title">
              Tracking emissions in the US electricity system
            </h1>
            <ul className="header">
              <li>
                <a href="https://jdechalendar.su.domains">
                  <h3>Jacques de Chalendar</h3>
                </a>
              </li>
              <li>
                <NavLink exact to="/">
                  Real-time map
                </NavLink>
              </li>
              <li>
                <NavLink to="/heatmaps">Carbon Intensity Heatmaps</NavLink>
              </li>
              <li>
                <NavLink to="/timeseries">Timeseries</NavLink>
              </li>
              <li>
                <NavLink to="/about">About</NavLink>
              </li>
              <li>
                <NavLink to="/code">Code and Data</NavLink>
              </li>
            </ul>
          </div>
          <div
            className="content"
            style={{ width: `${this.state.width}px`, margin: "auto" }}
          >
            <div style={{ marginBottom: "2em", color: "#820000" }}>
              <h2 style={{ color: "#820000" }}>December 2022 Update</h2>
              The U.S. Energy Information Administration has now adopted our methodology to make consumption-based electric sector emissions available through their <a href="https://www.eia.gov/electricity/gridmonitor/about">Hourly Electric Grid Monitor</a>. We will continue to maintain this site to enable comparisons (see "About" page).
            </div>
            <Route exact path="/">
              <views.Home width={this.state.width} />
            </Route>
            <Route path="/heatmaps" component={views.HeatmapGallery} />
            <Route path="/timeseries" component={views.TimeseriesGallery} />
            <Route path="/about" component={views.About} />
            <Route path="/code" component={views.Code} />
            <h2>Related publications</h2>
            <ol>
              <li>
                "Tracking emissions in the US electricity system", by Jacques A.
                de Chalendar, John Taggart and Sally M. Benson. Proceedings of
                the National Academy of Sciences Dec 2019, 116 (51) 25497-25502;{" "}
                <a href="https://doi.org/10.1073/pnas.1912950116">DOI</a>.
              </li>
              <li>
                "Physics-informed data reconciliation framework for real-time
                electricity and emissions tracking", by Jacques A. de Chalendar
                and Sally M. Benson. Applied Energy 304 (2021): 117761;{" "}
                <a href="https://doi.org/10.1016/j.apenergy.2021.117761">DOI</a>
                ; <a href="https://arxiv.org/abs/2103.05663">arXiv preprint</a>.
              </li>
            </ol>
          </div>
        </div>
      </HashRouter>
    );
  }
}

export default App;
